import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="About Our Pricing">
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`One of the things that sets Simple Review Management apart from its competitors is our unique results-based pricing model.`}</p>
    <p>{`We will explain the benefits in more detail later, but first let’s talk about how it works.`}</p>
    <h3 {...{
      "id": "what-does-results-based-pricing-mean"
    }}>{`What does results-based pricing mean?`}</h3>
    <p>{`Results-based pricing means the price is dependent on the results you get from the service. We are a review generation service, so when we say ‘results’ we are talking about positive reviews.`}</p>
    <p>{`Our customers are free to send as many review requests as they want throughout the month. At the end of the month we count the number of reviews that were a direct result of a review request and that is how we determine what the month’s bill will be.`}</p>
    <p>{`Let’s look at two examples`}</p>
    <ul>
      <li parentName="ul">{`20 review requests are sent through Simple RM in a month. None of the requests result in a review. The customer would be charged nothing at the end of the month.`}</li>
      <li parentName="ul">{`20 review requests are sent through Simple RM in a month and 7 of the requests result in a review. The customer would be charged for those seven reviews.`}</li>
    </ul>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
    Use this link to go back to the main <Link href="/pricing" mdxType="Link">Pricing page</Link>.
    </Message>
    <h3 {...{
      "id": "the-benefits-of-results-based-pricing"
    }}>{`The benefits of results-based pricing`}</h3>
    <p>{`Results-based pricing is perfect for new businesses. Most startups can’t afford to risk an expense that might not have a return. With results-based pricing there is no risk, every dollar is guaranteed to be well spent.`}</p>
    <p>{`Many service businesses have a slow season. With results-based pricing there is no need to cancel or pause your subscription during this time. Your bill naturally scales with your usage, right down to zero if you are not using it at all. `}</p>
    <p>{`Lastly, you can trust that our interests are aligned. We share the common goal of getting you as many reviews as possible and we are laser focused on this goal. That focus has already led to conversion boosting innovations like our Smart Send Time feature and we are confident it will lead to more in the future.`}</p>
    <h3 {...{
      "id": "why-do-we-offer-results-based-pricing"
    }}>{`Why do we offer results-based pricing?`}</h3>
    <p>{`Results-based pricing is more work for a business to offer than other pricing models. So why do we still choose to use it?`}</p>
    <p>{`We use it because we put our customers first and we aren’t just saying that. Offering this billing model is our way of proving that we want to work with our customers and succeed together.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      